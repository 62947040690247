import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes, _ROUTES } from './routes';

import store from '../store';
import { UserState } from '@/store/modules/user';

Vue.use(VueRouter)

const checkForUser = async (next: any, requiresAuth: boolean = false, to: any) => {
	await store.dispatch('checkForUser');
	// is user?
	if (!requiresAuth) {
		next();
		return;
	}

	const state = store.state as any;
	const user = state.user.profile;
	const authenticated = state.user.authenticated;

	if (user && authenticated) {
		if (to.path === '/login') {
			next('/');
		}

		next();
	} else {
		next({ name: 'login' });
	}

}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
	await store.dispatch('setLoading', true)
	const state = store.state as any;

	await checkForUser(next, !state.user.authenticated && to.matched.some(record => record.meta.requiresAuth), to);

});

router.afterEach(async (to, from) => {
	await store.dispatch('setLoading', false);
});





export default router
