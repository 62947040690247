import firebase from 'firebase/app';
import 'firebase/auth';
import store from '@/store';

const firebaseConfig = {
	apiKey: 'AIzaSyCXDvKIUe6kL4jR39hvEeEuJ2S2FXrfGWQ',
    authDomain: 'drumnow-apps.firebaseapp.com',
    databaseURL: 'https://drumnow-apps.firebaseio.com',
    projectId: 'drumnow-apps',
    storageBucket: 'drumnow-apps.appspot.com',
    messagingSenderId: '289335709014',
    appId: '1:289335709014:web:5a65cd49d18f430d8d5af7'
};

firebase.initializeApp(firebaseConfig);

firebase.auth().onAuthStateChanged(
    async (user: any) => {
        // when auth updates, call store methods
        await store.dispatch('checkForUser', user);
    }
);

export default firebase.auth();
