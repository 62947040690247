





























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Volume from '@/components/player/components/VolumeControls.vue';

@Component({
    components: {
        Volume
    }
})
export default class Controls extends Vue {
    @Prop({ default: false }) condensed!: boolean;
    @Prop({ default: false }) playing!: boolean;
    @Prop({ default: false }) looping!: boolean;
    @Prop({ default: false }) showVolume!: boolean;

    /**
     * Attributes for controls buttons
     */
    get btnAttrs() {
        return {
            class: 'dn-player__controls__btn',
            icon: true,
            color: 'primary',
            large: true
        }
    }
}
