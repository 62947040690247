import Vue from 'vue'
import Vuex from 'vuex'
import nprogress from 'nprogress';

import { ArticleModule } from './modules/article';
import { BrowseModule } from './modules/browse';
import { FormModule } from './modules/form';
import { HomeModule } from './modules/home';
import { PackModule } from './modules/pack';
import { PlayerModule } from './modules/player';
import { SnackbarModule } from './modules/snackbar';
import { UserModule } from './modules/user';
import { VideoModule } from './modules/video';

Vue.use(Vuex)

export interface IState {
	user: any;
	pack: any;
}

export default new Vuex.Store({
  state: {
	pack: {},
	loading: false,
	loaded: false,
  },
  mutations: {
	setLoading(state: any, value: boolean) {
		state.loading = value;
	}
  },
  actions: {
	setLoading({ commit }, value) {
		if (value) {
			nprogress.start();
		} else {
			nprogress.done();
		}

		commit('setLoading', value);
	},
	handleError({ dispatch }, error) {
		dispatch('pushMessage', error);
		dispatch('setLoading', false);
	}
  },
  modules: {
	article: ArticleModule,
	browse: BrowseModule,
	form: FormModule,
	home: HomeModule,
	pack: PackModule,
	player: PlayerModule,
	snackbar: SnackbarModule,
	user: UserModule,
	video: VideoModule,
  }
})
