import { ITimerParams } from '../interfaces';

/**
 * Player module to handle UI timing
 */
export class PlayerTimer {
    constructor(params: ITimerParams) {
        const {
            callback = () => {},
            interval = 1000
        } = params;

        this.callback = callback;
        this.interval = interval;
    }

    /**
     * Value of the timer
     */
    time = 0;

    /**
     * Timer id
     */
    timer: number = 0; 

    /**
     * Default timer interval
     */
    interval: number = 1000;

    /**
     * Timer callback function
     * No-op, override this when initializing the class
     */
    callback = (): any => {};

    /**
     * Start the current timer
     */
    start() {
        this.timer = setInterval(this.tick.bind(this), this.interval);
    }

    tick() {
        // tick the time value
        this.set(this.time + 1);

        // call the callback
        return this.callback();
    }

    /**
     * Stop the current timer
     */
    stop() {
        clearInterval(this.timer);
    }

    /**
     * Reset the timer state
     */
    reset() {
        clearInterval(this.timer);
        this.set(0);
    }

    /**
     * Restart the timer next interval
     */
    restart() {
        this.reset();
        this.start();
    }

    /**
     * Set timer to a value
     */
    set(value: number) {
        this.time = value;
    }
}