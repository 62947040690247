import axios, { AxiosInstance } from 'axios';
import io from 'socket.io-client';
import ss from 'socket.io-stream';
import createBuffer from 'audio-buffer-from';

import { Player, Context, Time } from 'tone';
import { IFormParams } from '@/interfaces/IFormParams';


const SOCKET_URLS = {
	local: 'http://localhost:3000/',
	dev: 'https://node.drumnow.app/',
	staging: 'https://node.drumnow.app/',
	prod: 'https://node.drumnow.app/'
}

const URLS = {
	local: 'http://localhost:3000/api/v1',
	dev: 'https://node-dev-lakinlggia-uc.a.run.app/api/v1',
	staging: 'https://node-staging-lakinlggia-uc.a.run.app/api/v1',
	prod: 'https://node.drumnow.app/api/v1'
};

const stream = ss.createStream();


// stream.pipe(new WritableStreamDefaultWriter())

const TIMEOUT = 50000;



interface Library {
	_id: string;
	
	meta: {
		credits: number
	};

	loops: any[];
	packs: any[];
	videos: any[];
}

const reader = new FileReader();

const getServiceUrl = (env: string = process.env.NODE_ENV) => {
	let nodeUrl = '';

	switch (env) {
		case 'development':
			nodeUrl = URLS.dev;
			break;
		case 'staging':
		case 'test':
			nodeUrl = URLS.staging;
			break;
		case 'production':
		default:
			nodeUrl = URLS.prod;
			break;
	}

	return nodeUrl;
}


export class NodeServiceClass {
	axios: AxiosInstance;
	// socket: SocketIOClient.Socket;
	stream: any;
	ss: any;

	constructor() {
		this.axios = axios.create({
			baseURL: getServiceUrl(),
			timeout: TIMEOUT,
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		});
	}

	addAuthHeader(token: string) {
		this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
	}

	signIn(email: string) {
		// sign in user using email and auth token
		return this.axios.post('/user/login', {
			email
		});
	}

	async getLibrary(library: Library) {
		// get all three things
		const packsPromises = library.packs.map(_id => this.axios.get(`/pack/${_id}`).then((data) => data.data));
		const loopsPromises = library.loops.map(_id => this.axios.get(`/loop/${_id}`).then((data) => data.data));
		const videosPromises = library.videos.map(_id => this.axios.get(`/video/${_id}`).then((data) => data.data));

		const packs = await Promise.all(packsPromises) || [];
		const loops = await Promise.all(loopsPromises) || [];
		const videos = await Promise.all(videosPromises) || [];

		return {
			_id: library._id,
			packs,
			loops,
			videos,
			credits: library.meta.credits
		}
	}

	addStripeTokenToUser(token: string, id: string) {
		return this.axios.put(`/user/${id}`, {
			stripeCustomer: token
		});
	}

	addTierToUser(tierId: string, id: string) {
		return this.axios.put(`/user/${id}`, {
			tier: tierId
		});
	}

	async getPack(id: string, getLoops: boolean = false) {
		return await this.axios.get(`/pack/${id}`);
	}

	async getPackLoops(id: string) {
		return await this.axios.get(`/pack/${id}/loops`);
	}

	getPacks() {
		return this.axios.get('/pack');
	}

	getVideos() {
		return this.axios.get('/video');
	}

	getVideo(id: string) {
		return this.axios.get(`/video/${id}`);
	}

	getArtists() {
		return this.axios.get('/artist');
	}

	getBanner(id: string) {
		return this.axios.get('/')
	}

	getArticle(slug: string) {
		return this.axios.get(`/article/slug/${slug}`);
	}

	updateUser(id: string, profile: any) {
		return this.axios.put(`/user/${id}`, profile);
	}

	updateUserAvatar(id: string, filename: string, file: File) {
		const formData = new FormData();
		formData.append('file', file);
		return this.axios.post(`/user/${id}/avatar/${filename}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	}

	submitForm(params: IFormParams) {
		const { email, message, followUp, subject } = params;
		
		return this.axios.post('/submit-form', {
			name: 'New Form Submit',
			email,
			message,
			requestsFollowUp: followUp,
			resolved: false,
			subject,
			submitDate: new Date()
		});
	}

	/**
	 * Creates a User with basic tier
	 * @param email 
	 * @param password 
	 */
	async createBasicUser(email: string, password: '') {
		let user;

		// get tier id
		const response = await this.axios.post('/user/create', {
			email,
			password
		});

		if (response.data.error) {
			return { success: false, message: response.data.error.message };
		}

		user = response.data.createdUser;

		return { success: true, user }
	}

	async getHomeCollections() {
		const collections = [
			'5ee9bd05018100000e2f1c1e',
			'5f70c3131fd1dd000ea61f2c',
			'5efab6b7928044000edbc345',
		];

		const data = await Promise.all(
			collections.map(
				async id => {
					const result = await this.axios.get(`/collection/${id}`);

					// filter unpublished content here for now

					return result.data;
				}
			)
		);

		return data;
	}

	async getHomeBanners() {

		const data = await this.axios.get(`/article/published`);

		// filter articles to top 10 published

		// filter to published
		const articles = data.data.filter((article: any) => article.published);

		return data.data;
	}


	async downloadPack(pid: string, lid: string) {
		const data = await this.axios.post('/library/pack/download', { pid, lid });
		return data.data;
	}

	async downloadLoop(lid: string, lpid: string, vid: string, pid: string) {
		const data = await this.axios.post('/library/loop/download', { lid, lpid, vid, pid });
		return data.data;
	}

	async streamLoop(lpid: string, vid: string) {
		const data = await this.axios.post('/library/loop/stream', { lpid, vid });
		return data.data;
	}
}

export const NodeService = new NodeServiceClass();
