import { SwarmService } from '@/services/swarm.service';
import { NodeService } from '@/services/node.service';
import { Module } from 'vuex';

export interface BrowseState {
	packs: [];
	videos: [];
	kits: [];
	artists: [];
	loading: boolean;
}

export const BrowseModule: Module<any, any> = {
	namespaced: true,
	state: {
		packs: [],
		videos: [],
		kits: [],
		artists: [],
		loading: false
	},
	mutations: {
		addPacks(state, packs) {
			const st = state as BrowseState;
			st.packs = packs.filter((pack: any) => pack.published);
		},
		addVideos(state, videos) {
			const st = state as BrowseState;
			st.videos = videos.filter((pack: any) => pack.published);
		},
		addArtists(state, artists) {
			const st = state as BrowseState;
			st.artists = artists;
		},
		toggleLoading(state, payload) {
			const st = state as BrowseState;
			st.loading = !st.loading;
		}
	},
	actions: {
		async getBrowseData({ commit, state }) {
			// call service to get data
			// commit('toggleLoading');
			const packs = await NodeService.getPacks();
			const videos = await NodeService.getVideos();
			const artists = await NodeService.getArtists();
			// set data to state
			commit('addPacks', packs.data);
			commit('addArtists', artists.data);
			commit('addVideos', videos.data);
			// commit('toggleLoading');
		}
	},
	modules: {
	}
}