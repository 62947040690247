import Vue from "vue";
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";

Sentry.init({
    dsn: 'https://bef1417621e643bcb72e9cc7dbf45d6d@o174932.ingest.sentry.io/5528053',
    integrations: [
        new VueIntegration({ 
            Vue,
            tracing: true
        }),
        new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0
})