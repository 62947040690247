 
import Tone, { Player, Transport, Loop, Time, TimeClass, Volume, now } from 'tone';
import tags from 'jsmediatags';

import { NodeService } from '@/services/node.service';

import {
    ILoadable,
    IPlayerOptions,
    IPlayerQueueOptions
} from './interfaces';

import { PlayerQueue, PlayerLogger, PlayerTimer } from './modules';

const DEFAULT_PLAYER_OPTIONS: IPlayerOptions = {
    loop: false,
    loopLength: '8t',
    shuffle: false,
    volume: -12
}

type PlayerMode = 'single' | 'queue';

export class PlayerEngine {
    constructor(options: IPlayerOptions = DEFAULT_PLAYER_OPTIONS) {
        const {
            loop = DEFAULT_PLAYER_OPTIONS.loop,
            loopLength = DEFAULT_PLAYER_OPTIONS.loopLength || '8t',
            shuffle = DEFAULT_PLAYER_OPTIONS.shuffle,
            volume = DEFAULT_PLAYER_OPTIONS.volume,
            debug = false
        } = options;

        this._debug = debug;
        this._loop = loop;
        this._loopLength = loopLength;
        this._shuffle = shuffle;
        this._queue = new PlayerQueue({ items: [] });
        this._logger = new PlayerLogger(debug);
        this._timer = new PlayerTimer({ callback: this.timerTick.bind(this) });
        this._volume = new Volume(volume).toDestination(); 
    }

    private _volume: Volume;
    private _queue: PlayerQueue;
    private _logger: PlayerLogger;
    private _timer: PlayerTimer;
    private _loopLength: string;
    private _loop: boolean;
    private _shuffle: boolean;
    
    private _mode: PlayerMode = 'queue';
    private _debug = false;
    private _playing = false;
    private _paused = false;
    private _meta = {};

    public get looping() {
        return this._loop;
    }

    public playing() {
        return Transport.state === 'started';
    }

    public get position() {
        // return Transport.position.toString();
        return this._timer.time;
    }

    public get length() {
        return 0;
    }

    public get duration() {
        return this.buffer?.duration || 0;
    }
    
    public get buffer(): AudioBuffer | undefined {
        return this._queue?.currentItem?.player?.buffer.get();
    }

    /**
     * load player with loops
     */
    public load(payload: ILoadable, loadQueue: boolean = true) {
        // create queue of players
        // this._logMessage('Loading payload', payload);

        const { items } =  payload;

        if (!items.length) {
            // this._logMessage('No items to load');
            return;
        }

        if (loadQueue) {
            // load a new queue
            this._loadQueue(payload);
        } else {
            // load a single file hmmm
            this._loadSingle(payload);
        }
    }

    public destroy() {
        // reset engine
        // this._queue = new PlayerQueue({ items: [] });
        // this._volume = new Volume(DEFAULT_PLAYER_OPTIONS.volume).toDestination(); 
        this._queue.destroy();
    }

    private _loadSingle(payload: ILoadable) {
        this._mode = 'single';
    }
    
    private _loadQueue(options: IPlayerQueueOptions) {
        this._mode = 'queue';
        // this._logMessage('Loading Queue', options);
        this._queue = new PlayerQueue(options);

        this._queue.load(this._volume);

        this._setCurrentItemLoop();
        this._loadMetadata();

        return this._queue;
    }

    private async _loadMetadata() { 
        // read and set metadata from current loop in queue
        // new tags.Reader(this._queue.currentItem.loopUrl)
        // .setTagsToRead(['title'])
        // .read({ 
        //     onSuccess: (tag: any) => {
        //         console.log(tag);
        //     },
        //     onError: (error: any) => {
        //         console.log(error);
        //     }
        // })
    }

    public playLoop(loop: any) {
        this._queue.set(loop);
        this.play();
    }
    
    /**
     * Start the player
     */
    public play() {
        // do queue logic here
        // this._stopCurrentItem();
        this._timer.reset();
        // this._timer.start();

        if (this.playing()) {
            this._stopCurrentItem();
        }

        if (this._queue.loaded) {
            this._loadMetadata();
            this._playCurrentItem();
        }
    }

    public get currentItem() {
        return this._queue.currentItem;
    }

    public setVolume(volume: number) {
        this._volume.volume.value = volume;
    }

    private _setBpm(bpm: number) {
        Transport.bpm.value = bpm;
    }

    private _setCurrentItemLoop() {
        this._queue.currentItem.player.loop = this._loop;
        this._queue.currentItem.player.loopEnd = this._loopLength;
    }

    private _stopCurrentItem() {
        this._timer.reset();
        Transport.stop();
        this._queue.currentItem.player.stop();
    }

    private async _playCurrentItem() {
        this._timer.start();
        // get real url
        const loopUrl = await NodeService.streamLoop(this._queue.currentItem._id, this._queue.currentItem.variantId);

        this._queue.currentItem.player.load(loopUrl).then(() => {
            this._setBpm(this._queue.currentItem.bpm);
            this._setCurrentItemLoop();
            this._queue.currentItem.player.start();
            Transport.start();
            // this._logMessage('Playing', this._queue.currentItem);
        });
        // }
        // set bpm
    }

    public timerTick(){
        // console.log(this._timer.time);
        return {};
    }

    /**
     * Pause the player
     */
    public pause() {
        this._stopCurrentItem();
    }

    /**
     * Skip to next track in queue
     */
    public ffw() {
        this._stopCurrentItem();
        if (this._queue.loaded) {
            this._queue.next();
            this._playCurrentItem();
        }
    }

    /**
     * Skip to previous track in queue
     */
    public frw() {
        this._stopCurrentItem();
        if (this._queue.loaded) {
            this._queue.previous();
            this._playCurrentItem();
        }
    }

    /**
     * Toggle Loop state for currently playing loop
     */
    public loop() {
        this._loop = !this._loop;
        this._queue.currentItem.player.loop = this._loop;
        // schedule an event??
        this._logger.message(`Set Looping to: ${this._loop}`);
    }
}