



































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';

// Components
import Appbar from '@/components/Appbar.vue';
import Sidebar from '@/components/Sidebar.vue';
import Snackbar from '@/components/Snackbar.vue';
import Loader from '@/components/Loader.vue';
import Player from '@/components/player-v2/Player.vue';

// Interfaces
import { IPlayerRef, IPlayer, ISidebarRef } from '@/interfaces';
import VueRouter, { Route, RouterOptions } from 'vue-router';
import { _ROUTES } from './router/routes';


@Component({
	name: 'App',
	components: {
		Sidebar,
		Snackbar,
		Player
	},
	computed: {
		...mapState([
			'loading',
			'pack'
		]),
	}
})
export default class App extends Vue {
	/* DATA PROPERTIES */
	
	loadPlayer = false;
	sidebarState: boolean = false;
	scrollHeight = 0;

	/* Computed Properties */

	get showSidebar() {
		return this.$route.name !== _ROUTES.PACK
			&& this.$route.name !== _ROUTES.LOGIN
			&& this.$route.name !== _ROUTES.TIERS
			&& this.$route.name !== _ROUTES.CHECKOUT
			&& this.$route.name !== _ROUTES.VIDEO
			&& this.$route.name !== _ROUTES.MAINTENANCE;
	}

	get showMenuIcon() {
		return !this.scrollHeight;
	}

	get showPlayer() {
		return this.$route.name === 'pack' && (this.$store.getters['USER__CAN_STREAM_LOOPS'] || this.$store.getters['PACK__IN_LIBRARY'])
	}

	get showToolbar() {
		return this.$route.name === 'home'
	}

	/* METHODS */

	handleLoopClick(loop: any) {
		// pass loop to player to handle
		const player = this.$refs.player as IPlayerRef;
		player.playLoop(loop);
	}

	/**
	 * Update Application page title
	 */
	updatePageTitle(title: string = 'Drumnow') {
		document.title = title;
	}

	/**
	 * Handle Toggling page sidebar state
	 */
	toggleSidebar() {
		// this.sidebarState = !this.sidebarState;
		const sidebar = this.$refs.sidebar as ISidebarRef;
		sidebar.value = !sidebar.value;
	}

	/**
	 * Callback for global scroll
	 */
	handleScroll() {
		this.scrollHeight = window.scrollY;
	}
	
	handleLoadPlayer(auth: boolean = false) {
		// smart load player by checking sent auth
		const player = this.$refs.player as IPlayerRef;
		
		if (auth) {
			// check route
			if (this.$route.name === 'pack') {
				this.$store.dispatch('updateThumbnail', { thumbnailUrl: this.$store.state.pack.pack.thumbnailUrl }).then(() => {
					this.$store.dispatch('loadPlayerItems', { items: this.$store.state.pack.pack.loops });
					player.load();
				});
			}

		}
	}

	handleDestroyPlayer() {
		const player = this.$refs.player as IPlayerRef;
		player.destroy();
	}

	/* WATCHERS */
	
	@Watch('$route')
	onRouteUpdate(to: Route, from: Route) {
		this.updatePageTitle(to.meta.title);
	}

	/* LIFECYCLE */

	mounted() {
		window.addEventListener('scroll', this.handleScroll);
	}

	beforeDestroy() {
		window.removeEventListener('scroll', this.handleScroll);	
	}
}
