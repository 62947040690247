import { SwarmService } from '@/services/swarm.service';
import { NodeService } from '@/services/node.service';
import { StoreOptions } from 'vuex';

export interface HomeState {
	collections: []
}

export const HomeModule: StoreOptions<{}> = {
	state: {
		collections: [],
		banners: []
	},
	mutations: {
		setHomeCollections(state: any, collections: []) {
			state.collections = collections;
		},
		setHomeBanners(state: any, collection: any) {
			state.banners = collection;
		}
	},
	actions: {
		async getHomeData({ commit, dispatch }) {
			const collections = await NodeService.getHomeCollections() || [];
			const banners = await NodeService.getHomeBanners() || [];

			if (!collections) {
				dispatch('pushMessage', 'It appears you are offline. Please try again once you are connected to the internet')
			}

			commit('setHomeCollections', collections);
			commit('setHomeBanners', banners);
		}
	},
	modules: {
	}
}