import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		dark: true,
		options: {
			customProperties: true
		},
		themes: {
			dark: {
				primary: '#36A098',
				secondary: '#1C4F4B',
				accent: '#40BDB2',
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FFC107',
			}
		}
	}
});
