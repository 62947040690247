import { SwarmService } from '@/services/swarm.service';
import { NodeService } from '@/services/node.service';
import { StoreOptions } from 'vuex';

export interface VideoState {

}

export const VideoModule: StoreOptions<{}> = {
	state: {
	},
	mutations: {
		
	},
	actions: {
		async getVideoData({ commit, state }, id) {
			const data = await NodeService.getVideo(id);
			return data.data;
		}
	},
	modules: {
	}
}