












































































import Tone, { Transport, Loop, Time, TimeClass, now } from 'tone';
import numeral from 'numeral';

import { PlayerState } from '@/store/modules/player';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { PlayerEngine } from '@/components/player/engine';

export type PlayerExpandedState = 'expanded' | 'closed';

import DEFAULT_THUMBNAIL from '@/assets/img/png/loading-pack-image.png';

import Settings from '@/components/player/components/SettingsControls.vue';
import Volume from '@/components/player/components/VolumeControls.vue';
import Controls from '@/components/player-v2/components/Controls.vue';
import { ILoadable } from '../player/engine/interfaces';

@Component({
    components: {
        Controls,
        Settings,
        Volume
    }
})
export default class Player extends Vue {

    constructor() {
        super();
        this.engine = new PlayerEngine(this.settings);
    }

    public settings = {
        loop: false,
        loopLength: '2m',
        volume: -8,
        shuffle: false,
        debug: true
    }

    public engine: PlayerEngine;
    
    get state(): PlayerState {
        return this.$store.state.player;
    }

    get expanded() {
        return this.state.expanded;
    }

    get playing() {
        return this.state.transport === 'started';
    }

    get looping() {
        return this.engine.looping;
    }

    get playerAttrs() {
        return {
            class: `dn-player ${this.expanded ? 'dn-player--expanded' : 'dn-player--collapsed'} ${this.$vuetify.breakpoint.smAndDown ? 'dn-player--condensed' : ''}`
        }
    }



    /**
     * Player Thumbnail
     */
    get thumbnail() {
        return this.state.thumbnailUrl || DEFAULT_THUMBNAIL;
    }

    get title() {
        return this.state.item.name || 'Title'
    }

    get subtitle() {
        return this.state.subtitle || 'Subtitle'
    }

    get position() {
        return numeral(this.engine.position).format('00:00:00');
    }

    duration() {
        return numeral(this.engine.duration).format('00:00:00');
    }

    currentLoop() {
        return this.state.items.find((loop: any) => loop._id === this.engine.currentItem._id) || {};
    }

    get transportState() {
        return Transport.state;
    }
    
    /**
     * toggle expanded player state
     */
    toggleExpanded() {
        // toggle expanded
        this.$store.commit('player:toggleExpanded');

        // document.body.classList.toggle('no-scroll')
    }

    load() {
        this.engine = new PlayerEngine(this.settings);
        this.loadState();
    }

    loadState() {
        this.engine.load(this._filterItems(this.state.items));
    }

    playLoop(loop: any) {
        this.engine.pause();
        this.engine.playLoop(loop);
        this._updateItem();
    }

    destroy() {
        this.engine.destroy();
        // clear state
        this.$store.dispatch('clearPlayerState');
    }

    /**
     * Update current item in state
     */
    _updateCurrentItem(item: any) {
        this.$store.dispatch('updatePlayerItem', { item });
    }

    _filterItems(items: any[]): ILoadable {
        const filtered: any = [];
        const currentTempo = this.state.tempo;
        const currentStyle = this.state.style;

        items.forEach((loop: any) => {
            const variant = loop.variants.filter((variant: any) => {
				// only load wet loops - REMOVE WHEN DATA IS CLEANED UP
				const isWet = variant.production.name.includes('Wet');

				// check the tempo
				const tempo = variant.tempo._id === currentTempo._id;

				// check the style
				const style = variant.style._id === currentStyle._id;

				return isWet && tempo && style;
			})[0];

			if (!variant) {
				return;
			}

			filtered.push(
				{
					_id: loop._id,
					variant
				}
			);
        })


        return { items: filtered }
    }

    // HANDLERS

    _handleThumbnailClick() {
        this.toggleExpanded();
    }

    _handleCloseButtonClick() {
        this.toggleExpanded();
    }

    /**
     * Event handler for Toggling play / pause
     */
    _handleTogglePlay() {

        this.playing
            ? this.engine.pause()
            : this.engine.play();

        this._updateItem();
    }

    /**
     * Event handler for next
     */
    _handleNext() {
        this.engine.ffw();
        this._updateItem();
    }

    /**
     * Event handler for Back
     */
    _handleBack() {
        this.engine.frw();
        this._updateItem();
    }

    _handleLoop() {
        this.engine.loop();
    }

    _updateItem() {
        this._updateCurrentItem(this.currentLoop());
    }

    _handleUpdate() {
        // if playing
        if (this.playing) {
            // stop player
            this.engine.pause();

            const loop = this.currentLoop();

            // load new sounds
            this.loadState();

            this.playLoop(loop);
            this._updateItem();
        }
    }

    _handleVolume(val: number) {
        this.engine.setVolume(val);
    }

    @Watch('transportState')
    _handleTransportUpdate(
        newVal: Tone.PlaybackState,
        oldVal: Tone.PlaybackState
    ) {
        // set state
        this.$store.commit('player:setPlayerState', {newVal, oldVal});
    }

    @Watch('looping')
    _handleLoopingStateUpdate() {
        this.$store.commit('player:toggleLooping');
    }
}
