import { NodeService } from '@/services/node.service';
import { StoreOptions } from 'vuex';
import nprogress from 'nprogress';

export interface PackState {
	pack: any,
	loading: boolean,
	loaded: boolean
}

export const PackModule: StoreOptions<PackState> = {
	state: {
		pack: {},
		loading: false,
		loaded: false
	},
	mutations: {
		setPack(state, data) {
			state.pack = data;
		},
		togglePackLoading(state) {
			state.loading = !state.loading;
		},
		setPackLoaded(state, value) {
			state.loaded = value;
		},
		setPackLoops(state, data) {
			state.pack.loops = data;
		}
	},
	getters: {
		PACK__IN_LIBRARY(state, getters, rootState) {
			const root = rootState as any;

			return !!root.user.profile.library?.packs.find((pack: any) => pack._id === state.pack._id)
		}
	},
	actions: {
		async getPackData({ commit, state, dispatch }, {id = '', auth = false}) {
			nprogress.start();
			commit('togglePackLoading');

			const data = await NodeService.getPack(id);
			
			commit('setPack', data.data);
			commit('togglePackLoading');

			if (!auth) {
				commit('setPackLoaded', true);
				nprogress.done();
			}


			return data.data;
		},
		async getPackLoops({ commit, state }, { id = '' }) {
			commit('togglePackLoading');

			const data = await NodeService.getPackLoops(id);

			commit('setPackLoops', data.data);
			commit('togglePackLoading');
			commit('setPackLoaded', true);

			nprogress.done();

			return data.data;
		}
	},
	modules: {
	}
}